export const complaints = [

    {
        title: "Our Promise",
        description: "Our complaints procedure is designed to ensure that each complaint is dealt with fairly and promptly."
    },
    {
        description: "We will endeavour, wherever possible, to resolve any complaint to your satisfaction at the time we are made aware. If we are unable to resolve your complaint straight away, we will send you an acknowledgement letter within 2 working days of the complaint being received."
    },
    {
        description: ''
    },
    {
        description: 'We are committed to resolving complaint as soon as possible and in any case, if after a 4-week period we are still not in a position to respond to you with either:'
    },
    {
        itemList: [
            {
                title: "A final response, or",
                description: ""
            },
            {
                title: "A holding response, which will explain why we are not in a position to resolve the complaint. We will also inform you when we make further contact regarding your complaint.",
                description: ""
            },
        ]
    },
    {
        description: 'In any case, after an 8-week period after receipt of your complaint, we will write to you with either:',
        itemList: [
            {
                title: "A final response",
            },
            {
                title: `A response which explains that we are still not in a position to give you a final response. We will also give you time frames as to when we feel we will be able to provide a response; and
                informs you of your right to refer your complaint to the Financial Ombudsman`,
            },  
        ]
    },
    {
        itemList: [
            {
                description: "Phone: [0300 123 9123](tel:03001239123) between 08:00 and 20:00 and 9am to 1pm Sat"
            },
            {
                description: "Email; Online complaint form"
            },
            {
                description: "Post: Exchange Tower, Harbour Exchange, London, E14 9SR"
            },
            {
                description: "Web: [www.financial-ombudsman.org.uk/contact](www.financial-ombudsman.org.uk/contact)"
            },
        ]
    },
    {
        description: 'The Financial Ombudsman can investigate complaints up to six years from the date of the problem happening or within three years of when you found out about the problem. If you wish to refer your complaint to the Financial Ombudsman this must be done within six months of our final response to your complaint. If your complaint is resolved within 3 days there is no requirement to escalate your complaint further.'
    },
    {
        description: 'United Legal Assistance is authorised and regulated by The Financial Conduct Authority, Company number: 08934259, Firm number: 629948'
    },
    {
        title: 'Contact Information - United Legal Assistance Limited',
        itemList: [
            {
                description: "Email: [contact us here](/contact-us)"
            },
            {
                description: "Address: 130C Lord Street, Southport, PR9 0AE"
            },
            {
                description: "Tel: [01704 468030](tel:01704468030)"
            },
            {
                description: "Company Number: 08934259"
            },
        ]
    }
]