import React from "react";
import Banner from "../components/banner/banner";
import Layout from "../layouts"
import ListItem from "../components/list-item/list-item";
import { complaints } from "../utils/legals/complaintsInformation";
import "../styles/pages/privacy-policy.scss";
import CustomMarkdown from "../utils/customMarkdown";

const ComplaintsProcedure = () => {

    return (

        <Layout
            SEOTitle={"Complaints Handling Procedure - United Legal Assistance"}
            SEODescription={"Our complaints procedure is designed to ensure that each complaint is dealt with fairly and promptly."}
            footerDisclaimer={""}>
                
            <div className="privacy-policy-container">

                <div style={{ margin: "40px 0" }}>
                    <h1 className="main-title">Complaints Handling Procedure</h1>
                    <b />
                </div>

                {
                    complaints.length ? complaints.map((item, index) => {

                        return (
                            <div key={index}>
                                {
                                    item.title ? <h3 className="bold">{item.title}</h3> : ""

                                }
                                {
                                    item.description ? Array.isArray(item.description) ? item.description.map((itemText, index) => {

                                        return (
                                            <p style={{ margin: "5px" }} key={index}>{itemText.text}</p>
                                        )
                                    }) : <p style={{ margin: "10px 0px 0px 0px" }}>{item.description}</p> : ""
                                }
                                {
                                    item.itemList ?
                                        <div style={{ paddingTop: "10px" }}>
                                            {
                                                item.itemList?.length ? item.itemList.map((listItem, index) => {

                                                    return (
                                                        <>
                                                            {listItem.title ? <ListItem text={listItem.title} key={index} /> : ""}
                                                            <CustomMarkdown style={{ margin: "0px" }}>{listItem.description}</CustomMarkdown>
                                                        </>
                                                    )
                                                }) : ""
                                            }

                                        </div>
                                        : ""
                                }
                            </div>
                        )
                    }) : ""
                }

            </div>
        </Layout>
    )
}

export default ComplaintsProcedure;